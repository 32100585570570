import React from "react";
import "../../styles/Page.scss";
import Layout from "../../components/Layout";
import PageInfo from "../../components/PageInfo";
import Note from "../../components/Note";

import schema from "../../content/advanced/images/verrouillage-signature-schéma.png";
import boutons from "../../content/advanced/images/verrouillage-boutons.png";
import validation from "../../content/advanced/images/verrouillage-validation.png";

export default function FeatLockSign() {
  return (
    <Layout title="Verrouillage et signature">
      <div className="page-body">
        <PageInfo />
        <div className="page-title">
          <h1>Verrouillage et signature</h1>
        </div>
        <section>
          <h2 id="étapes-de-verrouillage-d-un-formulaire">
            Étapes de verrouillage d'un formulaire
          </h2>
          <p>
            Au cours d'une étude, chaque formulaire doit passer plusieurs étapes
            depuis leur remplissage jusqu'à leur validation finale par le
            responsable de l'étude.
          </p>
          <img
            src={schema}
            alt="Verrouillage d'un formulaire"
            className="fullwidth"
            style={{ boxShadow: "none" }}
          />
          <p>
            Comme le montre le schéma ci-dessus, les formulaires disposent de 4
            statuts plus ou moins restreints et seuls certains rôles peuvent
            agir sur chaque étape du formulaire.
          </p>
          <table>
            <tr>
              <td>Statut du formulaire</td>
              <td>Objectif et rôles concernés</td>
              <td>Changements possibles</td>
            </tr>
            <tr>
              <td>
                <b>Ouvert</b>
              </td>
              <td>
                Collecte des données d'étude et remplissage par les <b>TEC</b>{" "}
                et <b>TEC+</b>.
              </td>
              <td>
                <b>Verrouiller le formulaire : </b>Tout le monde
              </td>
            </tr>
            <tr>
              <td>
                <b>Verrouillé</b>
              </td>
              <td>
                La collecte des données est bloquée, le formulaire est en
                attente d'être monitoré.
              </td>
              <td>
                <b>Monitorer le formulaire : </b>ARC/ARC coordonnateur ou
                Administrateur (global ou local)
                <br />
                <b>Déverrouiller le formulaire : </b>ARC/ARC coordonnateur ou
                Administrateur (global ou local)
              </td>
            </tr>
            <tr>
              <td>
                <b>Monitoré</b>
              </td>
              <td>
                Les données sont analysées par les <b>ARC</b> et{" "}
                <b>ARC coordonnateurs</b>. Les{" "}
                <a href="/fr/advanced/queries">
                  <b>queries</b>
                </a>{" "}
                sont toujours accessibles.
              </td>
              <td>
                <b>Signer le formulaire : </b>Investigateur et Investigateur
                coordonnateur, Administrateur (global ou local)
                <br />
                <b>Démonitorer le formulaire : </b>ARC/ARC coordonnateur ou
                Administrateur (global ou local)
              </td>
            </tr>
            <tr>
              <td>
                <b>Validé et signé</b>
              </td>
              <td>
                Étape finale du formulaire, les données sont approuvées par
                le(s) responsable(s) de l'étude.
              </td>
              <td>
                <b>Supprimer la signature du formulaire : </b>Administrateur
                (global ou local)
              </td>
            </tr>
          </table>
          <Note type="note">
            Pour comprendre la notion de rôle et les abréviations utilisées,
            vous pouvez consulter le guide sur les{" "}
            <a href="/fr/guides/roles">Rôles et droits</a>.
          </Note>
        </section>
        <section>
          <h2 id="verrouiller-monitorer-signer-un-formulaire">
            Verrouiller, monitorer ou signer un formulaire
          </h2>
          <p>
            Pour modifier l'état d'un formulaire, et ce quel que soit votre rôle
            dans l'étude, rendez-vous sur le formulaire concerné. Dans la barre
            de menu inférieure, un <b>bouton contextuel</b> s'affiche en
            fonction du changement que vous pouvez effectuer :
          </p>
          <img
            src={boutons}
            alt="Boutons de verrouillage"
            className="centered"
            style={{ boxShadow: "none" }}
          />
          <p>
            Cliquez sur le bouton approprié pour effectuer l'action souhaitée.
            Une fenêtre de validation s'ouvre, vous devez alors réinscrire au
            clavier le<b> code aléatoire à 4 chiffres</b> qui s'affiche à
            l'écran. Cette mesure permet d'assurer une sécurité supplémentaire
            dans la gestion des formulaires.
            <br />
            Le message affiché varie légèrement en fonction du changement
            effectué mais le principe reste le même pour chaque étape et chaque
            rôle.
          </p>
          <img
            src={validation}
            alt="Validation de verrouillage"
            className="centered"
          />
          <p>
            Cliquez sur le <b>bouton vert</b> pour terminer votre action. Un
            message s'affiche en bas à gauche de l'écran pour confirmer la
            procédure.
          </p>
        </section>
      </div>
    </Layout>
  );
}
